import { cn } from 'ui/lib/utils'
import { ElementType, FunctionComponent, PropsWithChildren } from 'react'
import MisterContainer from './MisterContainer'

interface Props {
  className?: string
  as?: ElementType
  horizontalPadding?: boolean
  verticalPadding?: boolean
}

const MisterFluidContainer: FunctionComponent<PropsWithChildren<Props>> = ({ children, className, as = 'div', horizontalPadding = true, verticalPadding = true }) => {
  return (
    <MisterContainer as={as} type='fluid' className={cn(horizontalPadding && 'xl:px-12', verticalPadding && 'py-10 xl:py-12', className)} padding={horizontalPadding}>
      {children}
    </MisterContainer>
  )
}

export default MisterFluidContainer
