import { FunctionComponent, useEffect, useRef } from 'react'
import { useRecentlyViewed } from '../../../contexts/RecentlyViewed.context'
import { useRouter } from 'next/router'
import { useQuery } from '@tanstack/react-query'
import MisterProductCard from '../../shared/productCard/MisterProductCard'
import { useToggle } from 'react-use'
import { cn } from 'ui/lib/utils'
import MisterFluidContainer from '../../shared/MisterFluidContainer'
import MisterSkeletonLoader from '../../shared/loader/MisterSkeletonLoader'
import useHasBeenInView from '../../../hooks/utils/useHasBeenInView'
import { getProductsForRecommendations } from 'data-access'
import { ProductCardProduct } from 'data-access/sanity/fragments/components/productCardProduct.fragment'

interface ProductsRowProps {
  title: string
  products: ProductCardProduct[]
  productsCount?: number // Used for skeleton loading
  isLoading?: boolean
  clearAvailable?: boolean
  clearText?: string
  clearHandler?: () => void
}

const ProductsRow: FunctionComponent<ProductsRowProps> = ({ title, products, productsCount = 3, isLoading = false, clearAvailable = false, clearText, clearHandler }) => {
  const router = useRouter()
  const [cleared, setCleared] = useToggle(false)
  const scrollRef = useRef<HTMLUListElement>(null)

  const clear = () => {
    clearHandler?.()
    setCleared(true)
  }

  // Reset scroll position on route change
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollLeft = 0
    }
  }, [router.asPath])

  return (
    <div className={cn('space-y-4 lg:space-y-6', cleared && 'hidden')}>
      <div className='flex items-center gap-3'>
        <h3 className='text-heading-5'>{title}</h3>
        {clearAvailable && (
          <button type='button' className='brand-medium-grey hover:underline' onClick={clear}>
            {clearText || '(clear)'}
          </button>
        )}
      </div>
      <ul ref={scrollRef} className='flex snap-x snap-mandatory gap-x-6 overflow-x-scroll scrollbar-hide' aria-busy={isLoading} aria-live='polite'>
        {!isLoading &&
          products?.map((product, i: number) => {
            return (
              <MisterProductCard
                className='min-w-[18rem] snap-center snap-always lg:w-[20.5rem] lg:min-w-[20.5rem]'
                key={`product-${i}`}
                index={i}
                product={{ ...product, _id: `${product._id}-recently-viewed` }}
                imageSizes='30rem'
                quickAddToCart={true}
                wishList={true}
              />
            )
          })}
        {isLoading &&
          [...Array(Math.min(3, productsCount))].map((_, i) => (
            <MisterSkeletonLoader key={`loader-${i}`} element='li' className='flex h-[31rem] w-[18rem] shrink-0 snap-center snap-always lg:h-[34.75rem] lg:w-[20.5rem]' />
          ))}
      </ul>
    </div>
  )
}

interface RecentlyViewedProductsProps {
  recentlyViewedText: string
  clearText: string
  maxProducts?: number
}

const RecentlyViewedProducts: FunctionComponent<RecentlyViewedProductsProps> = ({ recentlyViewedText, clearText, maxProducts = 30 }) => {
  const { locale } = useRouter()
  const { removeRecentlyViewed, recentlyViewedProductSlugs } = useRecentlyViewed()

  const { data, isLoading } = useQuery({
    queryKey: ['recentlyViewedProducts', recentlyViewedProductSlugs, locale, maxProducts],
    queryFn: () => getProductsForRecommendations(recentlyViewedProductSlugs, locale, maxProducts),
    staleTime: 1000 * 60 * 60, // 1h
    refetchOnWindowFocus: false,
    enabled: recentlyViewedProductSlugs?.length > 0,
  })

  // No longer loading an no results
  if (!isLoading && (data || [])?.length === 0) {
    return null
  }

  return (
    <ProductsRow
      title={recentlyViewedText}
      products={data || []}
      productsCount={recentlyViewedProductSlugs.length}
      isLoading={isLoading}
      clearAvailable={true}
      clearText={clearText}
      clearHandler={removeRecentlyViewed}
    />
  )
}

interface Props {
  recentlyViewedText: string
  clearText: string
  maxProducts?: number
  show?: boolean
}

const MisterFooterRecentlyViewed: FunctionComponent<Props> = ({ recentlyViewedText, clearText, maxProducts = 30, show = true }) => {
  const ref = useRef<HTMLDivElement>(null)
  const { hasBeenInView } = useHasBeenInView(ref, 10)
  const { recentlyViewedProductSlugs } = useRecentlyViewed()

  if (!show) {
    return null
  }

  return (
    <div ref={ref}>
      {hasBeenInView && recentlyViewedProductSlugs.length > 0 && (
        <MisterFluidContainer verticalPadding={true}>
          <RecentlyViewedProducts recentlyViewedText={recentlyViewedText} clearText={clearText} maxProducts={maxProducts} />
        </MisterFluidContainer>
      )}
    </div>
  )
}

export default MisterFooterRecentlyViewed
