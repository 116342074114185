import { Survey } from 'posthog-js'
import { usePostHog } from 'posthog-js/react'
import { FunctionComponent, useEffect, useState } from 'react'
import MisterInput from '../input/MisterInput'
import { toast } from 'react-toastify'
import { useTranslations } from 'src/contexts/Globals.context'
import { Button } from '@ui/components/ui/button'
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '@ui/components/ui/dialog'

interface Props {
  isOpen: boolean
  onClose: () => void
}

// "What can we do to improve our website?" survey id. Please
// note these event labels are fixed/determined by PostHog.
// @see https://eu.posthog.com/project/12378/surveys/018f1a41-a253-0000-86d9-f98fb45a8115
const surveyId: Readonly<string> = '018f3915-b90c-0000-ff60-c6e5fc9ea844'
const postHogSurveyShownEvent: Readonly<string> = 'survey shown'
const postHogSurveySentEvent: Readonly<string> = 'survey sent'
const postHogSurveyDismissedEvent: Readonly<string> = 'survey dismissed'

export const MisterSurvey: FunctionComponent<Props> = ({ isOpen, onClose }) => {
  const posthog = usePostHog()
  const [survey, setSurvey] = useState<Survey | undefined>(undefined)
  const [feedback, setFeedback] = useState<string | undefined>()
  const translate = useTranslations()

  useEffect(() => {
    posthog.getActiveMatchingSurveys((surveys) => {
      const firstSurvey = surveys.filter((survey) => survey.id === surveyId)[0]

      if (firstSurvey) {
        setSurvey(firstSurvey)
        posthog.capture(postHogSurveyShownEvent, {
          $survey_id: firstSurvey.id,
          $survey_name: firstSurvey.name,
        })
      }
    }, true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSurveySubmit = () => {
    if (!survey || !feedback) {
      return
    }

    posthog.capture(postHogSurveySentEvent, {
      $survey_id: survey.id,
      $survey_name: survey.name,
      $survey_response: feedback.substring(0, 2500),
    })

    toast(
      <div className='flex flex-col items-center justify-center gap-1'>
        <span className='text-heading-5'>{translate('thankYou', 'Thank you')}</span>
        <span>{translate('feedbackSubmitted', 'Your feedback has been submitted')}</span>
      </div>,
    )

    onClose()
  }

  const dismissSurvey = () => {
    posthog.capture(postHogSurveyDismissedEvent, {
      $survey_id: survey?.id,
      $survey_name: survey?.name,
    })

    onClose()
  }

  return (
    <Dialog open={isOpen} onOpenChange={dismissSurvey}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className='pr-4 text-heading-5'>{translate('feedbackSurveyTitle', 'What can we do to improve our website?')}</DialogTitle>
          <DialogDescription className='mt-2 text-body-md'>
            {translate('feedbackSurveyDescription', 'Please share your feedback with us. We appreciate your input.')}
          </DialogDescription>
        </DialogHeader>
        <MisterInput
          wrapperClassName='mt-5'
          inputClassName='outline-none'
          onChangeHandler={(e) => setFeedback(e?.feedback)}
          showLabel={false}
          type='textArea'
          id='feedback'
          label='feedback'
          name='feedback'
          textAreaRows={3}
          validate={false}
          required={true}
          variant='secondary'
          maxLength={2000}
        />
        <DialogFooter>
          <Button size='md' disabled={!feedback} onClick={handleSurveySubmit} type='submit'>
            {translate('submit', 'Submit')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
