import { RefObject, useCallback, useEffect } from 'react'
import { useToggle } from 'react-use'

export const useHasBeenInView = (ref: RefObject<HTMLElement | null>, percentageInView = 0) => {
  const [hasBeenInView, setHasBeenInView] = useToggle(false)
  const [hasBeenHorizontallyInView, setHasBeenHorizontallyInView] = useToggle(false)

  const observerCallback = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const entry = entries[0]

      if (entry?.isIntersecting) {
        setHasBeenInView(true)
      }

      if (entry?.intersectionRatio && entry.intersectionRatio > percentageInView) {
        setHasBeenHorizontallyInView(true)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [percentageInView],
  )

  useEffect(() => {
    const sectionRef = ref?.current

    if (!sectionRef) {
      return
    }

    const observer = new IntersectionObserver(observerCallback, {
      rootMargin: `0% ${percentageInView}% 0% ${percentageInView}%`,
      threshold: [percentageInView / 100, 1.0],
    })

    observer.observe(sectionRef)

    return () => {
      observer.unobserve(sectionRef)
    }
  }, [ref, observerCallback, percentageInView])

  return { hasBeenInView, hasBeenHorizontallyInView }
}

export default useHasBeenInView
